import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL

export default class AnalogsController {

    static async getAll () {
        return await axios.get(SERVER_URL+'/analogs')
    }

    static async getByIdMedical (id) {
        return await axios.get(SERVER_URL + '/analogs/' + id)
    }

    static async create (data) {
        return await axios.post(SERVER_URL + '/analogs', data)
    }

    static async delete (id) {
        return await axios.delete(SERVER_URL + '/analogs/' + id)
    }

}