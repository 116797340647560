import React from 'react';
import {Form, Input} from "antd";
import MyButton from "./UI/button/MyButton";
import AnalogsController from "../API/AnalogController";

const AnalogForm = (props) => {
    const prepAdd = async (values) => {
        values.medicationId = props.medicationId
        const response = await AnalogsController.create(values)
        props.create(response.data)
        form.resetFields()
    }

    const prepAddFailed = () => {
        // console.log('ffg')
    }
    const [form] = Form.useForm();
    return (
        <Form
            name="basic"
            form={form}
            layout="vertical"
            style={{ maxWidth: "100%", width: "600px" }}
            initialValues={{ remember: true }}
            onFinish={prepAdd}
            onFinishFailed={prepAddFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Название препарата"
                name="name"
                rules={[{ required: true, message: 'Обязательное поле' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Ссылка"
                name="link"
                rules={[{ required: true, message: 'Обязательное поле' }]}
            >
                <Input />
            </Form.Item>
            <MyButton type="primary" htmlType="submit" >Добавить</MyButton>
        </Form>
    );
};

export default AnalogForm;