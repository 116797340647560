import React from 'react';
import MedicalItem from "./MedicalItem";
import {Col, Row} from "antd";


const MedicalList = ({list, title, setVisible}) => {

    return (
        <div className="content">
            <h1>{title}</h1>
            <Row gutter={[16, 16]} className="medicalList">
                {list.map((prep, index) =>
                    <MedicalItem key={prep.id} prep={prep}  ></MedicalItem>
                )}
                <Col span={6} className="medicalItem addMedical" onClick={() => setVisible(true)}>+</Col>
            </Row >
        </div>
    );
};

export default MedicalList;