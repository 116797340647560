import React, {useEffect, useState} from 'react';
import MedicalController from "../API/MedicalController";
import {useFetching} from "../hooks/useFetching";
import MedicalList from "../components/MedicalList";
import MyModal from "../components/UI/MyModal/MyModal";
import MedicalForm from "../components/MedicalForm";
import {Layout} from "antd";
import Menu from "../components/Menu";

const Main = () => {
    const [medicals, setMedicals] = useState([])

    const [fetchPosts, isPostsLoading, postError] = useFetching(async () => {
        const response = await MedicalController.getAll()
        setMedicals([...medicals, ...response.data])
    })
    const createMedical = (newMedical) => {
        setMedicals([...medicals, newMedical])
        setModal(false)
    }

    const [modal, setModal] = useState(false)

    useEffect(() => {
        fetchPosts()
    }, []);

    return (
        <Layout>
            <Menu/>
            <MedicalList list={medicals} setVisible={setModal} title={'Список Препаратов'}></MedicalList>
            <MyModal visible={modal} setVisible={setModal} >
                <MedicalForm create={createMedical} ></MedicalForm>
            </MyModal>
        </Layout>

    );
};

export default Main;