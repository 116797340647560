import React from 'react';
import {Button, Space, Upload, message} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const DetailPicture = (props) => {
    let imgSRC = '';
    props.src
        ?
        imgSRC = props.src
        :
        imgSRC = '/img/no_photo.webp';
    const propsUpload = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    return (
        <div className="detailImg">
            <img src={imgSRC} alt=""/>
            {/*<Space>*/}
            {/*    <Upload {...propsUpload}>*/}
            {/*        <Button icon={<UploadOutlined />}>Загрузить изображение</Button>*/}
            {/*    </Upload>*/}
            {/*</Space>*/}
        </div>
    );
};

export default DetailPicture;