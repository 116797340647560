import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import {Button, Flex, Popconfirm, Space} from "antd";
import MedicalController from "../API/MedicalController";
import DetailPicture from "../components/DetailPage/DetailPicture";
import {useFetching} from "../hooks/useFetching";
import Comment from "../components/Comment";
import Analog from "../components/Analog";
import ParseResult from "../components/ParseResult";

const Detail = () => {
    const router = useNavigate()
    const params = useParams()
    const [openDel, setOpenDel] = useState(false);
    const [medical, setMedical] = useState({});
    const [fetchPost, isPostsLoading, postError] = useFetching(async () => {
        const response = await MedicalController.getById(params.id)
        setMedical(response.data)
    })
    const showPopconfirm = () => {
        setOpenDel(true);
    };
    const handleOk = async () => {
        const response = await MedicalController.delete(params.id)
        setOpenDel(false);
        router('/')
    };
    const handleCancel = () => {
        setOpenDel(false);
    };

    useEffect( () => {
        fetchPost()
    }, []);
    return (
        <Flex vertical={true}>
            <Link to="/">
                <Button type={"link"}>Назад</Button>
            </Link>
            <DetailPicture src={medical.image}/>
            <Space>
                <Button>Обновить</Button>
            </Space>
            <br/>
            <Space>
                <h1>{medical.name}</h1>
            </Space>
            <Space>
                <Link to={medical.link}>{medical.link}</Link>
            </Space>
            {params.id == 11
            &&
                <ParseResult/>
            }
            <Analog idMedical={params.id}/>

            <Comment title='Комментарий' medical={medical} medicalChange={setMedical}/>

            <Space>
                <Popconfirm
                    title="Удалить"
                    description="Вы уверены?"
                    okText="Да"
                    cancelText="Нет"
                    open={openDel}
                    onConfirm={handleOk}
                    onCancel={handleCancel}
                >
                    <Button danger onClick={showPopconfirm}>
                        Удалить препарат
                    </Button>
                </Popconfirm>
            </Space>
        </Flex>
    );
};

export default Detail;