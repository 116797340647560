import React from 'react';
import {Popconfirm, Table} from "antd";
import AnalogsController from "../API/AnalogController";

const AnalogList = ({analogs, deleteAnalog}) => {

    const handleOk = async (analog) => {
        const response = await AnalogsController.delete(analog.id)
        response.data
        &&
        deleteAnalog(analog)
    };
    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            defaultSortOrder: 'ascend',
            fixed: 'left',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Ссылка',
            dataIndex: 'link',
            key: 'link',
        },
        {
            title: 'Действие',
            dataIndex: '',
            render: (_, record) =>
                    <Popconfirm
                        title="Удалить"
                        description="Вы уверены?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => handleOk(record)}
                    >
                        <a>Удалить</a>
                    </Popconfirm>
        }

    ]
    return (
        <Table
            style={{margin: "30px 0"}}
            columns={columns}
            dataSource={analogs}
            // scroll={{ x: 2400 }}
            // onChange={onChange}
            // showSorterTooltip={{ target: 'sorter-icon' }}
        />
    );
};

export default AnalogList;