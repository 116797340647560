
import Main from "../pages/Main";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import Detail from "../pages/Detail";



export const privateRoutes = [
    {path: "/", element: <Main/>},
    {path: "/medication/:id", element: <Detail/>},
    {path: "*", element: <NotFound/>},
];

export const publicRoutes = [
    {path: "/", element: <Login/>},
    {path: "*", element: <NotFound/>}
];