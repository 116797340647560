import {BrowserRouter} from "react-router-dom";
import {useEffect, useState} from "react";
import {AuthContext} from "./context";
import AppRouter from "./components/AppRouter";
import "./styles/App.css";
import {Layout} from "antd";

function App() {
    const [isAuth, setIsAuth] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('auth')){
            setIsAuth(true)
        }
    }, []);

  return (
      <AuthContext.Provider value={{
          isAuth,
          setIsAuth
      }}>
          <BrowserRouter>
              <Layout className="mainBox">
                  <Layout className="content">
                    <AppRouter/>
                  </Layout>
              </Layout>
          </BrowserRouter>
      </AuthContext.Provider>
  );
}

export default App;
