import React, {useEffect, useState} from 'react';
import {Button, Flex} from "antd";
import AnalogList from "./AnalogList";
import {useFetching} from "../hooks/useFetching";
import AnalogsController from "../API/AnalogController";
import MedicalForm from "./MedicalForm";
import MyModal from "./UI/MyModal/MyModal";
import AnalogForm from "./AnalogForm";

const Analog = (props) => {
    const [analogs, setAnalogs] = useState([])
    const [modal, setModal] = useState(false)
    const [fetchAnalogs, isPostsLoading, postError] = useFetching(async () => {
        const response = await AnalogsController.getByIdMedical(props.idMedical)
        setAnalogs(response.data)
    })

    const addAnalog = (newAnalog) => {
        setAnalogs([...analogs, newAnalog])
        setModal(false)
    }

    const deleteAnalog = (analog) => {
        console.log('del start')
        console.log(analog)
        setAnalogs(analogs.filter(p => p.id !== analog.id))
        console.log('del end')
    }

    useEffect( () => {
        fetchAnalogs()
    }, []);

    return (
        <div style={{margin: "30px 0"}}>
            <div>Список аналогов</div>
            <AnalogList analogs={analogs} deleteAnalog={deleteAnalog}/>
            <Flex vertical>
                <Button onClick={()=>setModal(true)}>+</Button>
            </Flex>
            <MyModal visible={modal} setVisible={setModal} >
                <AnalogForm medicationId={props.idMedical} create={addAnalog}/>
            </MyModal>
        </div>
    );
};

export default Analog;