import React from 'react';
import cl from './MyInput.module.css'
import {Input} from "antd";

const MyInput = React.forwardRef( (props, ref) => {
    return (
        <Input ref={ref} className={cl.myInput} {...props}/>
    );
});

export default MyInput;