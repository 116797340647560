import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL

export default class MedicalController {

    static async getAll () {
        return await axios.get(SERVER_URL+'/medication')
    }

    static async getById (id) {
        return await axios.get(SERVER_URL + '/medication/' + id)
    }

    static async create (data) {
        return await axios.post(SERVER_URL + '/medication', data)
    }

    static async delete (id) {
        return await axios.delete(SERVER_URL + '/medication/' + id)
    }

}