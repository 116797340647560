import React, {useContext, useState} from 'react';
import MyInput from "../components/UI/input/MyInput";
import MyButton from "../components/UI/button/MyButton";
import {AuthContext} from "../context";
import {Form, Input} from "antd";

const Login = () => {
    const [form] = Form.useForm();
    const [password, setPassword] = useState('')
    const{isAuth, setIsAuth} = useContext(AuthContext)
    const auth = (value) => {
        console.log(value)
        if(value.login === 'manager'){
            if(value.password === '123456'){
                setIsAuth(true)
                localStorage.setItem('auth', 'true')
            }else{
                alert('ошибка')
            }
        }else{

        }
    }

    return (
        <div className="authBox">
            <h1>Страница авторизации</h1>
            <Form
                form={form}
                layout="vertical"
                style={{ maxWidth: "100%", width: "600px" }}
                initialValues={{ remember: true }}
                onFinish={auth}
            >
                <Form.Item
                    label="Логин"
                    name="login"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
                {/*<MyInput*/}
                {/*    value={login}*/}
                {/*    onChange={e => setLogin(e.target.value)}*/}
                {/*    type="text"*/}
                {/*    placeholder="Введите логин"*/}
                {/*/>*/}
                {/*<MyInput*/}
                {/*    value={password}*/}
                {/*    onChange={e => setPassword(e.target.value)}*/}
                {/*    type="password"*/}
                {/*    placeholder="Введите пароль"*/}
                {/*/>*/}
                <MyButton type="primary" htmlType="submit" >Войти</MyButton>
            </Form>
        </div>
    );
};

export default Login;