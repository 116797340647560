// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyButton_myBtn__2yioc{

}`, "",{"version":3,"sources":["webpack://./src/components/UI/button/MyButton.module.css"],"names":[],"mappings":"AAAA;;AAEA","sourcesContent":[".myBtn{\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myBtn": `MyButton_myBtn__2yioc`
};
export default ___CSS_LOADER_EXPORT___;
