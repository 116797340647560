import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div>
            <h1>ошибка 404</h1>
            <Link to="/">Вернутся на главную</Link>
        </div>
    );
};

export default NotFound;