// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyModal_myModal__pg-RL {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0,0,0, 0.5);
    z-index: 999;
}

.MyModal_meModalContent__PliVU{
    padding: 25px;
    background: white;
    border-radius: 16px;
    min-width: 250px;
}

.MyModal_myModal__pg-RL.MyModal_active__9tL7C{
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/MyModal/MyModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;IACP,aAAa;IACb,4BAA4B;IAC5B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".myModal {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    display: none;\n    background: rgba(0,0,0, 0.5);\n    z-index: 999;\n}\n\n.meModalContent{\n    padding: 25px;\n    background: white;\n    border-radius: 16px;\n    min-width: 250px;\n}\n\n.myModal.active{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myModal": `MyModal_myModal__pg-RL`,
	"meModalContent": `MyModal_meModalContent__PliVU`,
	"active": `MyModal_active__9tL7C`
};
export default ___CSS_LOADER_EXPORT___;
