import React from 'react';
import cl from "./MyButton.module.css";
import {Button} from "antd";

const MyButton = ({children, ...props}) => {
    return (
        <Button {...props} className={`${cl.myBtn} ${props.className}`}>
            {children}
        </Button>
    );
};

export default MyButton;