import React from 'react';
import {Button, Flex} from "antd";

const Menu = () => {
    return (
        <Flex gap="middle">
            <Button>
                Скачать файл
            </Button>
            <Button>
                Обновить
            </Button>
            <Button>
                История
            </Button>
        </Flex>
    );
};

export default Menu;