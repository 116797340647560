import React, {useState} from 'react';
import MyButton from "./UI/button/MyButton";
import {Button, Form, Input, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import MedicalController from "../API/MedicalController";

const MedicalForm = ({create}) => {

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const prepAdd = async (values) => {
        const response = await MedicalController.create(values)
        create(response.data)
        form.resetFields()
    }

    const prepAddFailed = () => {
        // console.log('ffg')
    }

    const [form] = Form.useForm();

    return (
        <Form
            name="basic"
            form={form}
            layout="vertical"
            style={{ maxWidth: "100%", width: "600px" }}
            initialValues={{ remember: true }}
            onFinish={prepAdd}
            onFinishFailed={prepAddFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Название препарата"
                name="name"
                rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                <Input />
            </Form.Item>
            <Form.Item
                label="Ссылка"
                name="link"
                rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                <Input />
            </Form.Item>
            {/*<Form.Item*/}
            {/*    name="image"*/}
            {/*    label="Изображение"*/}
            {/*    valuePropName="fileList"*/}
            {/*    getValueFromEvent={normFile}*/}
            {/*    extra=""*/}
            {/*>*/}
            {/*    <Upload name="logo" maxCount={1} action="http://localhost:5000/file/upload" listType="picture">*/}
            {/*        <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>*/}
            {/*    </Upload>*/}
            {/*</Form.Item>*/}
            <MyButton type="primary" htmlType="submit" >Добавить</MyButton>
        </Form>
);
};

export default MedicalForm;