import React from 'react';
import {Table} from "antd";

const ParseResult = () => {
    const dataold = [
        {
            "Column1": 30,
            "Препарат ": "Велсон",
            "Column3": 30,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 492.7458224803553,
            "Москва": 673,
            "Column7": 532,
            "Санкт-Петербург": 578,
            "Column9": 542,
            "Ростов-на-Дону": 518.9,
            "Column11": 554,
            "Нижний Новгород": 527,
            "Column13": 526,
            "Екатеринбург": 518.9,
            "Column15": 539,
            "Новосибирск": 532.8,
            "Column17": 553,
            "Хабаровск": 599,
            "Column19": 556,
            "Самара": 551.6,
            "Column21": 528,
            "Альфарм Цена \nSell Out": 555.5474535564988
        },
        {
            "Column1": 30,
            "Препарат ": "Меладапт",
            "Column3": 30,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 481.0213066504601,
            "Москва": 626,
            "Column7": 492,
            "Column9": 502,
            "Ростов-на-Дону": 445.5,
            "Column11": 462,
            "Нижний Новгород": 460,
            "Column13": 431,
            "Column15": 424,
            "Новосибирск": 461.9,
            "Column17": 454,
            "Column19": 431,
            "Самара": 514.4,
            "Column21": 443,
            "Альфарм Цена \nSell Out": 522.3254210768523
        },
        {
            "Column1": 24,
            "Препарат ": "Мелаксен",
            "Column3": 24,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 620.9341312817478,
            "Москва": 810,
            "Column7": 607,
            "Column9": 598,
            "Column11": 638,
            "Нижний Новгород": 565,
            "Column13": 583,
            "Екатеринбург": 521.4,
            "Column15": 571,
            "Самара": 549,
            "Альфарм Цена \nSell Out": 719.7075506877991
        },
        {
            "Column1": 10,
            "Препарат ": "Меларена",
            "Column3": 10,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 344.0578228941685,
            "Column9": 411,
            "Ростов-на-Дону": 373.1,
            "Column11": 402,
            "Column13": 432,
            "Column15": 370,
            "Новосибирск": 373.1,
            "Column17": 417,
            "Column19": 415,
            "Column21": 385,
            "Альфарм Цена \nSell Out": 431.1839594742607
        },
        {
            "Column1": 30,
            "Препарат ": "Меларена",
            "Column3": 30,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 468.1869510022272,
            "Москва": 605,
            "Column7": 494,
            "Ростов-на-Дону": 506.1,
            "Column11": 523,
            "Нижний Новгород": 488,
            "Column13": 489,
            "Column15": 479,
            "Новосибирск": 506.1,
            "Column17": 513,
            "Хабаровск": 480,
            "Column19": 514,
            "Самара": 510.1,
            "Column21": 495,
            "Альфарм Цена \nSell Out": 532.5145243886826
        },
        {
            "Column1": 30,
            "Препарат ": "Меларитм",
            "Column3": 30,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 570.8768285578598,
            "Column7": 669,
            "Column9": 680,
            "Ростов-на-Дону": 595.7,
            "Column11": 737,
            "Нижний Новгород": 675,
            "Column13": 678,
            "Екатеринбург": 277.9,
            "Column15": 665,
            "Новосибирск": 595.7,
            "Column17": 707,
            "Хабаровск": 697,
            "Самара": 595.7,
            "Column21": 684,
            "Альфарм Цена \nSell Out": 569.756912840746
        },
        {
            "Column1": 20,
            "Препарат ": "Мелатонин эвалар",
            "Column3": 20,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 225.8194873394762,
            "Москва": 325,
            "Column7": 285,
            "Санкт-Петербург": 224,
            "Column9": 299,
            "Ростов-на-Дону": 289.7,
            "Column11": 291,
            "Нижний Новгород": 428,
            "Column13": 299,
            "Column15": 281,
            "Новосибирск": 289.7,
            "Column17": 299,
            "Хабаровск": 309,
            "Column19": 294,
            "Column21": 281,
            "Альфарм Цена \nSell Out": 273.3751811273538
        },
        {
            "Column1": 40,
            "Препарат ": "Мелатонин эвалар",
            "Column3": 40,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 328.7880879048611,
            "Column7": 407,
            "Санкт-Петербург": 340,
            "Column9": 421,
            "Ростов-на-Дону": 422.2,
            "Column11": 420,
            "Нижний Новгород": 656,
            "Column13": 431,
            "Column15": 399,
            "Новосибирск": 422.2,
            "Column17": 422,
            "Хабаровск": 506,
            "Column19": 428,
            "Column21": 406,
            "Альфарм Цена \nSell Out": 385.3256036131463
        },
        {
            "Column1": 100,
            "Препарат ": "Мелатонин эвалар",
            "Column3": 100,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 621.5195855303494,
            "Москва": 793,
            "Column7": 794,
            "Санкт-Петербург": 643,
            "Column9": 788,
            "Column11": 820,
            "Нижний Новгород": 841,
            "Column13": 785,
            "Column15": 616,
            "Новосибирск": 843.5,
            "Column17": 818,
            "Хабаровск": 703,
            "Column19": 652,
            "Column21": 791,
            "Альфарм Цена \nSell Out": 680.6917777777778
        },
        {
            "Column1": 30,
            "Препарат ": "Мелатонин-сз",
            "Column3": 30,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 298.1217227753622,
            "Москва": 388,
            "Column7": 356,
            "Санкт-Петербург": 349,
            "Column9": 372,
            "Ростов-на-Дону": 432,
            "Column11": 369,
            "Нижний Новгород": 375,
            "Column13": 382,
            "Column15": 350,
            "Column17": 374,
            "Column19": 379,
            "Column21": 357,
            "Альфарм Цена \nSell Out": 369.6094696704213
        },
        {
            "Column1": 60,
            "Препарат ": "Мелатонин-сз",
            "Column3": 60,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 393.464537630707,
            "Москва": 555,
            "Column7": 528,
            "Column9": 534,
            "Column11": 548,
            "Нижний Новгород": 494,
            "Column13": 523,
            "Column15": 517,
            "Column17": 550,
            "Хабаровск": 494,
            "Column19": 555,
            "Column21": 530,
            "Альфарм Цена \nSell Out": 477.0617159028847
        },
        {
            "Column1": 90,
            "Препарат ": "Мелатонин-сз",
            "Column3": 90,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 559.8450308984313,
            "Column7": 616,
            "Column9": 628,
            "Ростов-на-Дону": 837.4,
            "Column11": 646,
            "Нижний Новгород": 620,
            "Column13": 613,
            "Column15": 597,
            "Column17": 640,
            "Column19": 648,
            "Column21": 588,
            "Альфарм Цена \nSell Out": 621.9757046178344
        },
        {
            "Column1": 30,
            "Препарат ": "Соннован",
            "Column3": 30,
            "Дозировка": "3 мг",
            "Альфарм Цена\n Sell in": 363.0867283591999,
            "Column7": 429,
            "Санкт-Петербург": 264,
            "Column9": 437,
            "Ростов-на-Дону": 383.8,
            "Column11": 446,
            "Нижний Новгород": 371,
            "Column13": 459,
            "Екатеринбург": 322,
            "Column15": 416,
            "Новосибирск": 383.8,
            "Column17": 444,
            "Хабаровск": 487,
            "Column19": 444,
            "Самара": 382.9,
            "Column21": 427,
            "Альфарм Цена \nSell Out": 367.0104597006912
        }
    ]

    const data = [
        {
            "key": 1,
            "name": "Велсон",
            "fas": 30,
            "doz": "3 мг",
            "sellIn": 492.7458224803553,
            "city1": 673,
            "city1Apt": 532,
            "city2": 578,
            "city2Apt": 542,
            "city3": 518.9,
            "city3Apt": 554,
            "city4": 527,
            "city4Apt": 526,
            "city5": 518.9,
            "city5Apt": 539,
            "city6": 532.8,
            "city6Apt": 553,
            "city7": 599,
            "city7Apt": 556,
            "city8": 551.6,
            "city8Apt": 528,
            "sellOut": 555.5474535564988
        },
        {
            "key": 2,
            "name": "Меладапт",
            "fas": 30,
            "doz": "3 мг",
            "sellIn": 481.0213066504601,
            "city1": 626,
            "city1Apt": 492,
            "city2Apt": 502,
            "city3": 445.5,
            "city3Apt": 462,
            "city4": 460,
            "city4Apt": 431,
            "city5Apt": 424,
            "city6": 461.9,
            "city6Apt": 454,
            "city7Apt": 431,
            "city8": 514.4,
            "city8Apt": 443,
            "sellOut": 522.3254210768523
        },
        {
            "key": 3,
            "name": "Мелаксен",
            "fas": 24,
            "doz": "3 мг",
            "sellIn": 620.9341312817478,
            "city1": 810,
            "city1Apt": 607,
            "city2Apt": 598,
            "city3Apt": 638,
            "city4": 565,
            "city4Apt": 583,
            "city5": 521.4,
            "city5Apt": 571,
            "city8": 549,
            "sellOut": 719.7075506877991
        },
        {
            "key": 4,
            "name": "Меларена",
            "fas": 10,
            "doz": "3 мг",
            "sellIn": 344.0578228941685,
            "city2Apt": 411,
            "city3": 373.1,
            "city3Apt": 402,
            "city4Apt": 432,
            "city5Apt": 370,
            "city6": 373.1,
            "city6Apt": 417,
            "city7Apt": 415,
            "city8Apt": 385,
            "sellOut": 431.1839594742607
        },
        {
            "key": 5,
            "name": "Меларена",
            "fas": 30,
            "doz": "3 мг",
            "sellIn": 468.1869510022272,
            "city1": 605,
            "city1Apt": 494,
            "city3": 506.1,
            "city3Apt": 523,
            "city4": 488,
            "city4Apt": 489,
            "city5Apt": 479,
            "city6": 506.1,
            "city6Apt": 513,
            "city7": 480,
            "city7Apt": 514,
            "city8": 510.1,
            "city8Apt": 495,
            "sellOut": 532.5145243886826
        },
        {
            "key": 6,
            "name": "Меларитм",
            "fas": 30,
            "doz": "3 мг",
            "sellIn": 570.8768285578598,
            "city1Apt": 669,
            "city2Apt": 680,
            "city3": 595.7,
            "city3Apt": 737,
            "city4": 675,
            "city4Apt": 678,
            "city5": 277.9,
            "city5Apt": 665,
            "city6": 595.7,
            "city6Apt": 707,
            "city7": 697,
            "city8": 595.7,
            "city8Apt": 684,
            "sellOut": 569.756912840746
        },
        {
            "key": 7,
            "name": "Мелатонин эвалар",
            "fas": 20,
            "doz": "3 мг",
            "sellIn": 225.8194873394762,
            "city1": 325,
            "city1Apt": 285,
            "city2": 224,
            "city2Apt": 299,
            "city3": 289.7,
            "city3Apt": 291,
            "city4": 428,
            "city4Apt": 299,
            "city5Apt": 281,
            "city6": 289.7,
            "city6Apt": 299,
            "city7": 309,
            "city7Apt": 294,
            "city8Apt": 281,
            "sellOut": 273.3751811273538
        },
        {
            "key": 8,
            "name": "Мелатонин эвалар",
            "fas": 40,
            "doz": "3 мг",
            "sellIn": 328.7880879048611,
            "city1Apt": 407,
            "city2": 340,
            "city2Apt": 421,
            "city3": 422.2,
            "city3Apt": 420,
            "city4": 656,
            "city4Apt": 431,
            "city5Apt": 399,
            "city6": 422.2,
            "city6Apt": 422,
            "city7": 506,
            "city7Apt": 428,
            "city8Apt": 406,
            "sellOut": 385.3256036131463
        },
        {
            "key": 9,
            "name": "Мелатонин эвалар",
            "fas": 100,
            "doz": "3 мг",
            "sellIn": 621.5195855303494,
            "city1": 793,
            "city1Apt": 794,
            "city2": 643,
            "city2Apt": 788,
            "city3Apt": 820,
            "city4": 841,
            "city4Apt": 785,
            "city5Apt": 616,
            "city6": 843.5,
            "city6Apt": 818,
            "city7": 703,
            "city7Apt": 652,
            "city8Apt": 791,
            "sellOut": 680.6917777777778
        },
        {
            "key": 10,
            "name": "Мелатонин-сз",
            "fas": 30,
            "doz": "3 мг",
            "sellIn": 298.1217227753622,
            "city1": 388,
            "city1Apt": 356,
            "city2": 349,
            "city2Apt": 372,
            "city3": 432,
            "city3Apt": 369,
            "city4": 375,
            "city4Apt": 382,
            "city5Apt": 350,
            "city6Apt": 374,
            "city7Apt": 379,
            "city8Apt": 357,
            "sellOut": 369.6094696704213
        },
        {
            "key": 11,
            "name": "Мелатонин-сз",
            "fas": 60,
            "doz": "3 мг",
            "sellIn": 393.464537630707,
            "city1": 555,
            "city1Apt": 528,
            "city2Apt": 534,
            "city3Apt": 548,
            "city4": 494,
            "city4Apt": 523,
            "city5Apt": 517,
            "city6Apt": 550,
            "city7": 494,
            "city7Apt": 555,
            "city8Apt": 530,
            "sellOut": 477.0617159028847
        },
        {
            "key": 12,
            "name": "Мелатонин-сз",
            "fas": 90,
            "doz": "3 мг",
            "sellIn": 559.8450308984313,
            "city1Apt": 616,
            "city2Apt": 628,
            "city3": 837.4,
            "city3Apt": 646,
            "city4": 620,
            "city4Apt": 613,
            "city5Apt": 597,
            "city6Apt": 640,
            "city7Apt": 648,
            "city8Apt": 588,
            "sellOut": 621.9757046178344
        },
        {
            "key": 13,
            "name": "Соннован",
            "fas": 30,
            "doz": "3 мг",
            "sellIn": 363.0867283591999,
            "city1Apt": 429,
            "city2": 264,
            "city2Apt": 437,
            "city3": 383.8,
            "city3Apt": 446,
            "city4": 371,
            "city4Apt": 459,
            "city5": 322,
            "city5Apt": 416,
            "city6": 383.8,
            "city6Apt": 444,
            "city7": 487,
            "city7Apt": 444,
            "city8": 382.9,
            "city8Apt": 427,
            "sellOut": 367.0104597006912
        }
    ]

    const columns = [
        {
            title: 'Препарат',
            dataIndex: 'name',
            key: 'name',
            defaultSortOrder: 'ascend',
            fixed: 'left',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Фасовка',
            dataIndex: 'fas',
            key: 'fas',
            sorter: (a, b) => a.fas - b.fas,
        },
        {
            title: 'Дозировка',
            dataIndex: 'doz',
            key: 'doz',
            sorter: (a, b) => a.doz.length - b.doz.length,
        },
        {
            title: 'Производитель',
            dataIndex: 'maker',
            key: 'maker',
            sorter: (a, b) => a.maker.length - b.maker.length,
        },
        {
            title: 'Sell In Цена, RUB',
            dataIndex: 'sellIn',
            key: 'sellIn',
            sorter: (a, b) => a.sellIn - b.sellIn,
        },
        {
            title: 'Москва',
            dataIndex: 'city1',
            key: 'city1',
            sorter: (a, b) => a.city1 - b.city1,
        },
        {
            title: 'Москва Аптека.ру',
            dataIndex: 'city1Apt',
            key: 'city1Apt',
            sorter: (a, b) => a.city1Apt - b.city1Apt,
        },
        {
            title: 'Санкт-Петербург',
            dataIndex: 'city2',
            key: 'city2',
            sorter: (a, b) => a.city2 - b.city2,
        },
        {
            title: 'Санкт-Петербург Аптека.ру',
            dataIndex: 'city2Apt',
            key: 'city2Apt',
            sorter: (a, b) => a.city2Apt - b.city2Apt,
        },
        {
            title: 'Ростов-на-Дону',
            dataIndex: 'city3',
            key: 'city3',
            sorter: (a, b) => a.city3 - b.city3,
        },
        {
            title: 'Ростов-на-Дону Аптека.ру',
            dataIndex: 'city3Apt',
            key: 'city3Apt',
            sorter: (a, b) => a.city3Apt - b.city3Apt,
        },
        {
            title: 'Нижний Новгород',
            dataIndex: 'city4',
            key: 'city4',
            sorter: (a, b) => a.city4 - b.city4,
        },
        {
            title: 'Нижний Новгород Аптека.ру',
            dataIndex: 'city4Apt',
            key: 'city4Apt',
            sorter: (a, b) => a.city4Apt - b.city4Apt,
        },
        {
            title: 'Екатеринбург',
            dataIndex: 'city5',
            key: 'city5',
            sorter: (a, b) => a.city5 - b.city5,
        },
        {
            title: 'Екатеринбург Аптека.ру',
            dataIndex: 'city5Apt',
            key: 'city5Apt',
            sorter: (a, b) => a.city5Apt - b.city5Apt,
        },
        {
            title: 'Новосибирск',
            dataIndex: 'city6',
            key: 'city6',
            sorter: (a, b) => a.city6 - b.city6,
        },
        {
            title: 'Новосибирск Аптека.ру',
            dataIndex: 'city6Apt',
            key: 'city6Apt',
            sorter: (a, b) => a.city6Apt - b.city6Apt,
        },
        {
            title: 'Хабаровск',
            dataIndex: 'city7',
            key: 'city7',
            sorter: (a, b) => a.city7 - b.city7,
        },
        {
            title: 'Хабаровск Аптека.ру',
            dataIndex: 'city7Apt',
            key: 'city7Apt',
            sorter: (a, b) => a.city7Apt - b.city7Apt,
        },
        {
            title: 'Самара',
            dataIndex: 'city8',
            key: 'city8',
            sorter: (a, b) => a.city8 - b.city8,
        },
        {
            title: 'Самара Аптека.ру',
            dataIndex: 'city8Apt',
            key: 'city8Apt',
            sorter: (a, b) => a.city8Apt - b.city8Apt,
        },
        {
            title: 'Альфарм Цена Sell Out',
            dataIndex: 'sellOut',
            key: 'sellOut',
            sorter: (a, b) => a.sellOut - b.sellOut,
        },
    ]

    return (
        <Table
            style={{margin: "30px 0"}}
            columns={columns}
            dataSource={data}
            scroll={{ x: 2400 }}
            // onChange={onChange}
            // showSorterTooltip={{ target: 'sorter-icon' }}
        />
    );
};

export default ParseResult;