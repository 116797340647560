import React, {useState} from 'react';
import {Input, Flex, Button, Form} from "antd";

const { TextArea } = Input;

const Comment = (props) => {
    const [form] = Form.useForm();
    const saveComment = (value) => {
        console.log(value)
        // props.medicalChange({...props.medical, comment: comment})
    }

    return (

        <Form
            form={form}
            layout="vertical"
            style={{margin: '30px 0'}}
            onFinish={saveComment}
        >
            <Form.Item
                label={props.title}
                name="comment"
            >
                <TextArea></TextArea>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">Сохранить</Button>
            </Form.Item>

        </Form>
    );
};

export default Comment;