import React from 'react';
import {useNavigate} from "react-router-dom";
import {Card, Col} from "antd";

const MedicalItem = (props) => {
    const router = useNavigate()
    let imgSrc = 'img/no_photo.webp'
    if(props.prep.image){
        imgSrc = props.prep.image
    }

    return (
        <Col span={6} className="medicalItem" onClick={() => router(`/medication/${props.prep.id}`)}>
            <Card>
                <div className="medicalItem_img">
                    <img src={imgSrc} alt={props.prep.name}/>
                </div>
                <div className="medicalItem_name">{props.prep.name}</div>
            </Card>
        </Col>
    );
};

export default MedicalItem;